<template>
  <div class="resource-pagination" :class="{ hidden: hidden }">
    <el-pagination
      layout="slot"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :hide-on-single-page="true"
      :total="total"
      v-bind="$attrs"
    >
      <div @click="handlerprev" class="prevBtn" v-if="this.page - 1 > 0">
        <!-- 上一页 -->
        <img
          src="https://image.bookgo.com.cn/webculture/jm/pagination/prevBtn.png"
          alt=""
        />
      </div>
    </el-pagination>
    <el-pagination
      background
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="pager"
      :total="total"
      v-bind="$attrs"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="slot"
      :hide-on-single-page="true"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div
        @click="handlernext"
        class="nextBtn"
        v-if="this.page + 1 <= this.totalPage"
      >
        <!-- 下一页 -->
        <img
          src="https://image.bookgo.com.cn/webculture/jm/pagination/nextBtn.png"
          alt=""
        />
      </div>
      <!-- <div @click="handleLast" class="lastBtn">
        尾页
      </div> -->
    </el-pagination>
    <!-- <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="slot"
      :total="total"
      v-bind="$attrs"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @next-click="handlernext"
      @current-change="handleCurrentChange"
    >
      <div class="currentPage">
        当前第{{ page }}页 / 共{{ Math.ceil(total / limit) }}页 共{{
          total
        }}条数据
      </div>
    </el-pagination>
    <el-pagination
      layout="slot"
      @next-click="handlernext"
      :total="total"
      :page-size="limit"
      :current-page="page"
      :hide-on-single-page="true"
    >
      <div class="jump-page-div">
        <div>转到</div>
        <div>
          <input
            class="jump-page-div-input"
            v-model="jumpPage"
            type="text"
            @blur="handlegopage"
          />
        </div>
        <div class="goPage" @click="handlegopage">GO</div>
      </div>
    </el-pagination> -->
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 15,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jumpPage: "",
    };
  },
  computed: {
    totalPage: {
      get() {
        return Math.ceil(this.total / this.limit);
      },
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        //console.log("===>", val)
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    loadPage(page) {
      this.$emit("update:page", page);
      this.$emit("pagination", { page: this.currentPage, limit: this.limit });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handlerprev() {
      if (this.page - 1 <= 0) {
        this.$message({
          message: "没有上一页了",
          type: "warning",
        });
        return;
      } else {
        this.loadPage(this.page - 1);
      }
    },
    handlegopage() {
      let gopage = parseInt(this.jumpPage);
      this.loadPage(gopage);
    },
    handlernext() {
      if (this.page + 1 > this.totalPage) {
        this.$message({
          message: "没有下一页了",
          type: "warning",
        });
      } else {
        this.loadPage(this.page + 1);
      }
    },
    handleLast() {
      let totalPage = Math.ceil(this.total / this.limit);
      if (this.page === totalPage) {
        this.$message({
          message: "已经是最后一页了",
          type: "warning",
        });
        return;
      }
      this.loadPage(totalPage);
    },
    handleSizeChange(val) {
      console.log(val);
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: (92rem / 100) auto;
  margin-bottom: 0;
  .el-pagination {
    display: flex;
    align-items: center;
    margin-right: 0.2rem;

    .prevBtn,
    .nextBtn {
      width: (20rem / 100);
      height: (34rem / 100);
      cursor: pointer;
      img {
        width: 100%;
      }
    }

    .lastBtn {
      width: (60rem / 100);
      margin-left: (10rem / 100);
    }

    .currentPage {
      font-size: (16rem / 100);
      font-weight: normal;
    }
  }

  .el-pager li {
    font-size: 0.14rem;
    min-width: (40rem / 100);
    height: (40rem / 100);
    line-height: (40rem / 100);
    box-sizing: border-box;
    text-align: center;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    width: (32rem / 100);
    height: (32rem / 100);
    line-height: (32rem / 100);
    border-radius: 50%;
    background-color: transparent;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev {
    margin: 0 5px;
    background-color: #f4f4f5;
    color: #32424f;
    min-width: 0.4rem;
    border-radius: 50%;
  }

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #cd9e56 !important;
    color: #fff;
  }

  .jump-page-div {
    display: flex;
    align-items: center;
    font-size: (16rem / 100);
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #32424f;
    line-height: 0.2rem;

    .jump-page-div-input {
      width: (40rem / 100);
      height: (40rem / 100);
      background-color: transparent;
      border-radius: 0.05rem;
      border: 1px solid #8492c6;
      font-size: 0.2rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #32424f;
      line-height: 0.4rem;
      margin: 0 0.08rem;
      text-align: center;
    }

    .goPage {
      width: (40rem / 100);
      height: (40rem / 100);
      border: 1px solid #8492c6;
      border-radius: (5rem / 100);
      background-color: #fff;
      text-align: center;
      line-height: (40rem / 100);
      cursor: pointer;
    }
  }
}
</style>

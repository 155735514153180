var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-container"},[_c('ul',{staticClass:"list-container-ul"},[_c('img',{staticClass:"border-top",attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/border-top.png","alt":""}}),(_vm.list.length > 0)?_c('div',_vm._l((_vm.list),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.toNewPage(item)}}},[_c('div',{staticClass:"list-container-ul-title",on:{"mouseover":function($event){_vm.current = i},"mouseleave":function($event){_vm.current = -1}}},[_c('img',{style:(_vm.a),attrs:{"src":_vm.current == i ? _vm.activeSrc : _vm.inactiveSrc,"alt":""}}),_c('p',{class:_vm.$route.path == '/cmsCommon-party'
                ? _vm.current == i
                  ? 'partyActive'
                  : ''
                : _vm.current == i
                ? 'titleActive'
                : ''},[_vm._v(" "+_vm._s(item.title)+" ")])]),(_vm.type != 1)?_c('div',{staticClass:"list-container-ul-abstract"},[_c('p',[_vm._v(_vm._s(item.summary))])]):_vm._e(),(_vm.type != 1)?_c('div',{staticClass:"list-container-ul-time"},[_vm._v(" 时间："+_vm._s(item.publish_date)+" ")]):_vm._e()])}),0):_c('div',{staticClass:"list-main-noContent"},[_vm._m(0),_c('p',[_vm._v("暂无内容，请搜索其它内容试试吧！")])]),_c('img',{staticClass:"border-bottom",attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/border-bottom.png","alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-main-noContent-img"},[_c('img',{attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/search/common-noContent.png","alt":""}})])
}]

export { render, staticRenderFns }
import request from '@/utils/request'


/**
 * 文化资讯
 * @param {*} data
 */
// 文章分类列表
export function cateList(data) {
    return request({
        url: '/v2/cms/category/list',
        method: 'post',
        params: data
    })
}
// 文章列表
export function list(data) {
    return request({
        url: '/v2/cms/article/index',
        method: 'post',
        params: data
    })
}


/**
 * 文化资讯
 * @param {*} data
 */
export function info(data) {
    return request({
        url: '/v2/cms/article/detail',
        method: 'post',
        params: data
    })
}
export function getpid(data) {
    return request({
        url: '/v2/cms/category/detail',
        method: 'post',
        params: data
    })
}
/**
 * 文化资讯
 * @param {*} data
 */
export function recommend_article(data) {
    return request({
        url: '/v2/culture/index/recommend_article',
        method: 'post',
        params: data
    })
}

<template>
  <div class="list-container">
    <ul class="list-container-ul">
      <img
        src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
        alt=""
        class="border-top"
      />
      <div v-if="list.length > 0">
        <li v-for="(item, i) in list" :key="i" @click="toNewPage(item)">
          <div
            class="list-container-ul-title"
            @mouseover="current = i"
            @mouseleave="current = -1"
          >
            <img
              :src="current == i ? activeSrc : inactiveSrc"
              alt=""
              :style="a"
            />
            <p
              :class="
                $route.path == '/cmsCommon-party'
                  ? current == i
                    ? 'partyActive'
                    : ''
                  : current == i
                  ? 'titleActive'
                  : ''
              "
            >
              {{ item.title }}
            </p>
          </div>
          <div class="list-container-ul-abstract" v-if="type != 1">
            <p>{{ item.summary }}</p>
          </div>
          <div class="list-container-ul-time" v-if="type != 1">
            时间：{{ item.publish_date }}
          </div>
        </li>
      </div>
      <div class="list-main-noContent" v-else>
        <div class="list-main-noContent-img">
          <img
            src="https://image.bookgo.com.cn/webculture/jm/search/common-noContent.png"
            alt=""
          />
        </div>
        <p>暂无内容，请搜索其它内容试试吧！</p>
      </div>
      <img
        src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
        alt=""
        class="border-bottom"
      />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: [Object, Array],
      required: true,
    },
    inactiveSrc: {
      type: String,
      default:
        "https://image.bookgo.com.cn/webculture/jm/interact/interact-problem-inactive.png",
    },
    activeSrc: {
      type: String,
      default:
        "https://image.bookgo.com.cn/webculture/jm/interact/interact-problem-active.png",
    },
    type:{
      type:String,
    },
  },
  data() {
    return {
      current: -1,
      a: {
        width: "0.09rem",
        height: "0.16rem",
        "margin-right": "0.08rem",
      },
      path: "",
    };
  },
  created() {
    if (this.$route.path == "/cmsCommon-party") {
      this.a.width = "0.24rem";
      this.a.height = "0.24rem";
    }
    this.path = this.$route.path;
  },
  methods: {
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `${item.tpl_code}/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
.list-container {
  &-ul {
    width: 12rem;
    margin: 0 auto;
    position: relative;
    padding: 0.36rem 0.3rem;
    background-color: #fff;
    .border-top,
    .border-bottom {
      width: 100%;
      position: absolute;
      left: 0;
    }
    .border-top {
      top: 0;
    }
    .border-bottom {
      bottom: 0;
    }
    li {
      // height: 1.49rem;
      padding: 0.2rem 0;
      border-bottom: 0.01rem solid #d8d8d8;
      cursor: pointer;
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      p {
        width: 11.15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.18rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: var("--color");
        line-height: 0.28rem;
      }
      .titleActive {
        color: #cd9e56;
      }
      .partyActive {
        color: #761f1e;
      }
    }
    &-abstract {
      margin: 0.11rem 0;
      p {
        width: 11.4rem;
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #273143;
        line-height: 0.22rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &-time {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #56657f;
      line-height: 0.14rem;
    }
  }
}
.list-main-noContent {
  width: 100%;
  height: 5.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-img {
    width: 2.18rem;
    height: 2.86rem;
    margin-bottom: 0.24rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273143;
    line-height: 0.16rem;
  }
}
</style>
